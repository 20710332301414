//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import normalHead from '@/views/components/ishop/header.vue'
	import shopList from '@/views/components/ishop/shopList'
	import myCart from '@/views/components/ishop/dialogs/myCart'
	import customShop from './step2CustomShop.vue'
	import { mapMutations } from 'vuex'
	import shopDetail from '@/views/components/ishop/dialogs/shopDetail'
	import { miceService } from '@/service/miceService'
	export default {
		name: 'step1SelectShop',
		components: {
			normalHead,
			shopList,
			myCart, //已选择商店
			customShop, //手工录入自定义餐厅页
			shopDetail
		},
		data() {
			return {
				headValue: { //nav配置
					haveSearch: true,
					haveBack: true,
					haveRule: true,
				},
				cart: { //选择的商户信息
					true: true,
					select: 0,
					sum: this.$store.state.ishop.maxQty5,
					itemType: 5,
					shops: []
				},
				params: { //参数
					cityId: '',
					hospitalId: "",
					pageIndex: 1,
					pageSize: 20,
					keyword: '',
					distance: this.$store.state.ishop.ishopParam.distance,
					maxPriceAvg: this.$store.state.ishop.ishopParam.maxPriceAvg,
					minPriceAvg: this.$store.state.ishop.ishopParam.minPriceAvg,
				},
				// pagingShopData: [],//所有的商户信息
				retameShop: {}, //手工录入商家
				// sessionShops:[]//历史选择商家
				// isDisable: false //禁用滚动
				isSubmitData: false
			}
		},
		computed: {
			getCartSelect: function() { //已选择的个数
				return this.cart.select;
			},
			getCartSum: function() { //还可以选择的个数
				if(this.cart.sum < 0) {
					this.cart.sum = 0
				}
				return this.cart.sum;
			},
			isDisable: function() {
				return this.$store.state.ishop.isDisable
			}
		},
		watch: {
			// isDisable: function (val,oldVal) {
			//   if
			// }
		},
		created() {
			this.Bus.$on('myCartList', arr => { //监听shopList变化
				this.cart.select = arr.length
				this.cart.sum = this.$store.state.ishop.maxQty5 - Number(arr.length)
				this.cart.shops = arr
			});
			this.params.hospitalId = sessionStorage.getItem("hospitalId")
			this.params.cityId = sessionStorage.getItem("pingRegionId")
			// this.lookRule()//进入页面直接弹出提示
		},
		mounted() {
			this.Bus.$on("recommendShop", shops => { //监听手工录入变化
				this.retameShop = shops
			});
			this.Bus.$on("selectCartShops", arr => { //监听未过期已推荐的餐厅库餐厅
				this.cart.select = arr.length
				this.cart.sum = this.$store.state.ishop.maxQty5 - Number(arr.length)
				this.cart.shops = arr
			});
		},
		methods: {
			...mapMutations([
				'changeisDisable'
			]),
			unshift: function() {
				var res = [];
				var json = {};
				for(var i = 0; i < this.length; i++) {
					if(!json[this[i]]) {
						res.push(this[i]);
						json[this[i]] = 1;
					}
				}
				return res;
			},

			jump() {
				this.$router.push("/step2CustomShop")
			},
			changePropu() {
				var changePopup = true
				this.Bus.$emit('changePopup', changePopup);
			},
			openPropu() {
				this.changeisDisable(true)
				var openPropu = true
				this.Bus.$emit('openPropu', openPropu);
			},
			submitData: function() {
				if(this.isSubmitData) {
					this.toast({
						message: '正在提交ing..请勿重复操作',
						duration: 800,
					});
					return;
				}
				this.isSubmitData = true
				this.postShops()
			},
			postShops: async function() {
				for(var i = 0, length = this.retameShop.length; i < length; i++) {
					if(!this.retameShop[i].name && !this.retameShop[i].tel && !this.retameShop[i].tel) {
						this.retameShop.splice(i, 1)
					}
				}
				this.cart.shops.unshift.apply(this.cart.shops, this.retameShop)
				//过滤已保存的餐厅 TODO
				this.cart.shops = this.cart.shops.filter((ele)=>{
					return !ele.recommendId
				})
				if(this.cart.shops.length < 1){
					this.toast('您尚未添加新餐厅');
					this.isSubmitData = false;
					return false;
				}
				let params = {
					cityId: this.params.cityId,
					hospitalId: this.params.hospitalId,
					itemType: 5,
					shops: this.cart.shops,
					channel: localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
				}
				let pass = localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
				const res = await miceService.postMyShops(params)
				if(res) {
					this.isSubmitData = false
				}
				if(res.status) {
					if(pass == 'app' && this.$store.state.ishop.meesageRule) {
						this.messageBox({
							message: this.$store.state.ishop.meesageRule,
							confirmButtonText: '确定',
							closeOnClickModal: false
						}).then(action => {
							this.$router.push('/myShops')
						})
					} else {
						this.$router.push('/myShops')
					}
				} else {
					alert(res.msg)
				}
			},
			lookRule: function() {
				this.messageBox({
					title: '餐厅推荐规则',
					// message: `1、距离医院3km内<br/>2、能够开具正规发票<br/>3、同意发票暂存在餐厅<br/>4、有包房或独立隔断区域<br/>5、仅接受机打水单或小票`,
					message: `1、距离医院3km内<br/>2、能够开具正规发票<br/>3、有包房或独立隔断区域<br/>4、仅接受机打水单或小票`,
					confirmButtonText: '已知晓'
				});
			},
		}
	}
